import * as React from "react";
import {ASSETS} from "../common/images";
import Breadcrumbs from "../common/breadcrumbs";

const Banner = () => {
    return (
 <section className="gb-banner-wrapper gb-portfolio-banner">
            <img src={ASSETS.HOME.CommonHeaderShape4} className="common-header-shape-4" alt="common-header-shape-4" />
            <span className="common-header-shape-7" />
            <span className="common-header-shape-8" />
            <div className="container">
                <Breadcrumbs pageTitle={'Portfolio'} currentPage={'Portfolio'} />
            </div>
        </section>
    )
}

export default Banner;
