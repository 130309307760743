import * as React from "react";
import Layout from "../components/layout";
import Banner from "../components/portfolio/banner";
import PortfolioTabs from "../components/portfolio/portfolio-tabs";

const PortfolioPage = () => {
  return (
      <Layout>
          <Banner />
          <PortfolioTabs />
      </Layout>
  )
}
export default PortfolioPage;
