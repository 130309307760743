import {ASSETS} from "../common/images";
import * as React from "react";

const PortfolioTabs = () => {
    return (
        <section className="portfolio-tabs-wrapper-main">
            <div className="row">
                <div className="col-md-12">

                    <nav className="portfolio-tabs-wrapper">
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-all-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all"
                                    aria-selected="true">ALL
                            </button>
                            <button className="nav-link" id="nav-web-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-web" type="button" role="tab"
                                    aria-controls="nav-web" aria-selected="false">WEB
                            </button>
                            <button className="nav-link" id="nav-ios-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-ios" type="button" role="tab"
                                    aria-controls="nav-ios" aria-selected="false">iOS
                            </button>
                            <button className="nav-link" id="nav-ios-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-android" type="button" role="tab"
                                    aria-controls="nav-android" aria-selected="false">ANDROID
                            </button>
                            <button className="nav-link" id="nav-hybrid-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-hybrid" type="button" role="tab"
                                    aria-controls="nav-hybrid" aria-selected="false">HYBRID
                            </button>
                            <button className="nav-link" id="nav-iot-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-iot" type="button" role="tab"
                                    aria-controls="nav-iot" aria-selected="false">IOT
                            </button>

                        </div>
                    </nav>
                </div>
                <div className="col-md-12">
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab">

                            <div className="row common-portfolio-wrapper">
                                <div className="col-md-6 p-0 ">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">sgs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-sgs">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">sport and leisure</h4>
                                                <p className="description">Sport and leisure goods are an important and expanding area of business internationally, with leisure and fitness becoming more popular.</p>
                                                <p className="description">Bicycles in particular are booming due to the increasing popularity of biking for practical and leisure purposes, and because of their important role in protecting the environment.</p>
                                                <p className="description">But because sporting goods may carry the risk of injury they are also highly regulated and you need to understand the impact of safety requirements and regulations on your products. Ensuring that your products are of the highest quality and comply with regulations means that you can minimise</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row common-portfolio-wrapper flex-column-reverse flex-md-row">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-all-in">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">all in one</h4>
                                                <p className="description">In the past decade, millions of children around the world have gained access to educational opportunities. But there is still much work to be done.</p>
                                                <p className="description">The children excluded from learning opportunities are among the most vulnerable and hard to reach in the world. They come from the poorest households and often have to work to help support their families. Some face discrimination as ethnic minorities. Others live with disabilities. Most often they are girls. The Out-of-School Children Initiative is a global effort to uncover data and details about the children left behind and offer policy recommendations and interventions that will allow every last child to go to school and learn.</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project2})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project2})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">all in one</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row common-portfolio-wrapper">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project3})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project3})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">AUSTAMIL NEWS</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-austamil">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">AUSTAMIL NEWS</h4>
                                                <p className="description">Sport and leisure goods are an important and expanding area of business internationally, with leisure and fitness becoming more popular.</p>
                                                <p className="description">Bicycles in particular are booming due to the increasing popularity of biking for practical and leisure purposes, and because of their important role in protecting the environment.</p>
                                                <p className="description">But because sporting goods may carry the risk of injury they are also highly regulated and you need to understand the impact of safety requirements and regulations on your products. Ensuring that your products are of the highest quality and comply with regulations means that you can minimise</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row common-portfolio-wrapper flex-column-reverse flex-md-row">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-all-in">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">all in one</h4>
                                                <p className="description">In the past decade, millions of children around the world have gained access to educational opportunities. But there is still much work to be done.</p>
                                                <p className="description">The children excluded from learning opportunities are among the most vulnerable and hard to reach in the world. They come from the poorest households and often have to work to help support their families. Some face discrimination as ethnic minorities. Others live with disabilities. Most often they are girls. The Out-of-School Children Initiative is a global effort to uncover data and details about the children left behind and offer policy recommendations and interventions that will allow every last child to go to school and learn.</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project2})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project2})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">all in one</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-web" role="tabpanel"
                             aria-labelledby="nav-web-tab">
                            <div className="row common-portfolio-wrapper">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">sgs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-sgs">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">sport and leisure</h4>
                                                <p className="description">Sport and leisure goods are an important and expanding area of business internationally, with leisure and fitness becoming more popular.</p>
                                                <p className="description">Bicycles in particular are booming due to the increasing popularity of biking for practical and leisure purposes, and because of their important role in protecting the environment.</p>
                                                <p className="description">But because sporting goods may carry the risk of injury they are also highly regulated and you need to understand the impact of safety requirements and regulations on your products. Ensuring that your products are of the highest quality and comply with regulations means that you can minimise</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row common-portfolio-wrapper flex-column-reverse flex-md-row">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-all-in">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">all in one</h4>
                                                <p className="description">In the past decade, millions of children around the world have gained access to educational opportunities. But there is still much work to be done.</p>
                                                <p className="description">The children excluded from learning opportunities are among the most vulnerable and hard to reach in the world. They come from the poorest households and often have to work to help support their families. Some face discrimination as ethnic minorities. Others live with disabilities. Most often they are girls. The Out-of-School Children Initiative is a global effort to uncover data and details about the children left behind and offer policy recommendations and interventions that will allow every last child to go to school and learn.</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project2})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project2})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">all in one</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row common-portfolio-wrapper">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">sgs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-sgs">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">sport and leisure</h4>
                                                <p className="description">Sport and leisure goods are an important and expanding area of business internationally, with leisure and fitness becoming more popular.</p>
                                                <p className="description">Bicycles in particular are booming due to the increasing popularity of biking for practical and leisure purposes, and because of their important role in protecting the environment.</p>
                                                <p className="description">But because sporting goods may carry the risk of injury they are also highly regulated and you need to understand the impact of safety requirements and regulations on your products. Ensuring that your products are of the highest quality and comply with regulations means that you can minimise</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-ios" role="tabpanel"
                             aria-labelledby="nav-ios-tab">
                            <div className="row common-portfolio-wrapper">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">sgs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-sgs">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">sport and leisure</h4>
                                                <p className="description">Sport and leisure goods are an important and expanding area of business internationally, with leisure and fitness becoming more popular.</p>
                                                <p className="description">Bicycles in particular are booming due to the increasing popularity of biking for practical and leisure purposes, and because of their important role in protecting the environment.</p>
                                                <p className="description">But because sporting goods may carry the risk of injury they are also highly regulated and you need to understand the impact of safety requirements and regulations on your products. Ensuring that your products are of the highest quality and comply with regulations means that you can minimise</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row common-portfolio-wrapper flex-column-reverse flex-md-row">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-all-in">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">all in one</h4>
                                                <p className="description">In the past decade, millions of children around the world have gained access to educational opportunities. But there is still much work to be done.</p>
                                                <p className="description">The children excluded from learning opportunities are among the most vulnerable and hard to reach in the world. They come from the poorest households and often have to work to help support their families. Some face discrimination as ethnic minorities. Others live with disabilities. Most often they are girls. The Out-of-School Children Initiative is a global effort to uncover data and details about the children left behind and offer policy recommendations and interventions that will allow every last child to go to school and learn.</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project2})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project2})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">all in one</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-android" role="tabpanel"
                             aria-labelledby="nav-android-tab">
                            <div className="row common-portfolio-wrapper">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">sgs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-sgs">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">sport and leisure</h4>
                                                <p className="description">Sport and leisure goods are an important and expanding area of business internationally, with leisure and fitness becoming more popular.</p>
                                                <p className="description">Bicycles in particular are booming due to the increasing popularity of biking for practical and leisure purposes, and because of their important role in protecting the environment.</p>
                                                <p className="description">But because sporting goods may carry the risk of injury they are also highly regulated and you need to understand the impact of safety requirements and regulations on your products. Ensuring that your products are of the highest quality and comply with regulations means that you can minimise</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-hybrid" role="tabpanel"
                             aria-labelledby="nav-hybrid-tab">
                            <div className="row common-portfolio-wrapper">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">sgs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-sgs">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">sport and leisure</h4>
                                                <p className="description">Sport and leisure goods are an important and expanding area of business internationally, with leisure and fitness becoming more popular.</p>
                                                <p className="description">Bicycles in particular are booming due to the increasing popularity of biking for practical and leisure purposes, and because of their important role in protecting the environment.</p>
                                                <p className="description">But because sporting goods may carry the risk of injury they are also highly regulated and you need to understand the impact of safety requirements and regulations on your products. Ensuring that your products are of the highest quality and comply with regulations means that you can minimise</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row common-portfolio-wrapper flex-column-reverse flex-md-row">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-all-in">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">all in one</h4>
                                                <p className="description">In the past decade, millions of children around the world have gained access to educational opportunities. But there is still much work to be done.</p>
                                                <p className="description">The children excluded from learning opportunities are among the most vulnerable and hard to reach in the world. They come from the poorest households and often have to work to help support their families. Some face discrimination as ethnic minorities. Others live with disabilities. Most often they are girls. The Out-of-School Children Initiative is a global effort to uncover data and details about the children left behind and offer policy recommendations and interventions that will allow every last child to go to school and learn.</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project2})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project2})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">all in one</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row common-portfolio-wrapper">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">sgs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-sgs">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">sport and leisure</h4>
                                                <p className="description">Sport and leisure goods are an important and expanding area of business internationally, with leisure and fitness becoming more popular.</p>
                                                <p className="description">Bicycles in particular are booming due to the increasing popularity of biking for practical and leisure purposes, and because of their important role in protecting the environment.</p>
                                                <p className="description">But because sporting goods may carry the risk of injury they are also highly regulated and you need to understand the impact of safety requirements and regulations on your products. Ensuring that your products are of the highest quality and comply with regulations means that you can minimise</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-iot" role="tabpanel"
                             aria-labelledby="nav-iot-tab">
                            <div className="row common-portfolio-wrapper">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project1})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">sgs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-sgs">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">sport and leisure</h4>
                                                <p className="description">Sport and leisure goods are an important and expanding area of business internationally, with leisure and fitness becoming more popular.</p>
                                                <p className="description">Bicycles in particular are booming due to the increasing popularity of biking for practical and leisure purposes, and because of their important role in protecting the environment.</p>
                                                <p className="description">But because sporting goods may carry the risk of injury they are also highly regulated and you need to understand the impact of safety requirements and regulations on your products. Ensuring that your products are of the highest quality and comply with regulations means that you can minimise</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row common-portfolio-wrapper flex-column-reverse flex-md-row">
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-desc-wrapper project-all-in">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-md-12">
                                                <h4 className="title text-uppercase">all in one</h4>
                                                <p className="description">In the past decade, millions of children around the world have gained access to educational opportunities. But there is still much work to be done.</p>
                                                <p className="description">The children excluded from learning opportunities are among the most vulnerable and hard to reach in the world. They come from the poorest households and often have to work to help support their families. Some face discrimination as ethnic minorities. Others live with disabilities. Most often they are girls. The Out-of-School Children Initiative is a global effort to uncover data and details about the children left behind and offer policy recommendations and interventions that will allow every last child to go to school and learn.</p>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column justify-content-end align-items-center">
                                                <a href="void(0)" className="live-website-btn text-capitalize text-center">live website</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="common-portfolio-img-wrapper" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project2})` }}>
                                        <div className="common-portfolio-img-inner-wrapper">
                                            <div className="common-portfolio-img" style={{ backgroundImage: `url(${ASSETS.PORTFOLIO.Project2})` }}>
                                            </div>
                                            <p className="text-uppercase project-name">all in one</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    )
}
export default PortfolioTabs;